@import "epicore_share.scss";


/* GOOD EARTH SCSS */
body#body.epicore{
	#epicore-content{
		padding: 3rem 0;
		#sidebar{
			.widget{
				.heading{
					.title{
						font-size: 1.5rem;
						font-weight: bold;
					}
				}
			}
		}
		h1.page-title{
			font-size: 2rem;
		}

		.module {
		  margin: 0 -2px 0 0;
		  padding: 10px;
		  background: #ffffff; /* USER CUSTOMIZABLE */
		  border: 1px solid #333333; border-top: 0;
		}

		.wrapper {
		  width: 100%;
		}

		.container {
		  width: 100%;
		  margin: 0 auto;
		}

		.marquee { /* USER CUSTOMIZABLE - ON/OFF */
		  width: 100%;
		  height: 140px;
		  display: none;
		  border: 1px solid #333333;
		  margin: 10px 0 0 0 ;
		}

		.lower-content {
		  width: 100%;
		}

		.switch_status_store {
			display: block;
		}

		.customer-info p {
		  margin: 12px 0 8px 0;
		}

		.cart a.view-cart{
		  width: auto;
		  margin: 0 10px 0 0;
		  padding: 0 0 0 23px; /* USER CUSTOMIZABLE - RELATED TO ABOVE*/
		}

		.cart a.checkout{
		  width: auto;
		  padding: 0 0 0 21px; /* USER CUSTOMIZABLE - RELATED TO ABOVE*/
		}

		#custom_links form input {
		  float: right;
		  margin: 3px 5px 0 0;
		  height: 14px;
		  padding: 1px;
		  font-family: Verdana, sans-serif;
		}

		#custom_links form button {
		  width: 11px;
		  height: 11px;
		  border: 0;
		  text-indent: -9999px;
		  float: right;
		  margin: 8px 8px 0 0;
		}

		.nav-data {
		  margin-right: 5px;
		  margin-top: 2px;
		}

		#category_menubox .smallselectbox {
			width: 130px;
		}


		/**************************/
		/*    INET MENU CLASSES    */
		/**************************/


		/* SIDEBAR */
		.sidebar {
		  width: 20%;
		  float: left;
		}


		#menuboxes{
			.nav-menu {
				margin: 0;
				padding: 10px;
				border: 1px solid #333333;
				border-top: 0;
			}
			ul {
				width: auto;
				margin: 0 0 0 5px;
				list-style-type: none;
				li {
					margin: 5px 0;
					padding: 0 0 0 12px;
					&.first {
		  				margin-top: 0;
					}
				}
			}
		}




		/****************************/
		/*   INET DISPLAY CLASSES    */
		/****************************/



		.generic_box {
			background-color: #eeeeee; 
			border:1px solid #cccccc;
		}

		.tooltip {
			background-color: #ffffff;
			border: 1px solid #333333;
			padding: 2px;
		}


		#order_details .generic_box {
			background-color: #eeeeee;
			border: 1px solid #cccccc;
		}


		.celltext {
			color: #333333;
			text-decoration: none;
		}

		/* Generic Data */
		.celldata {
			color: #333333;
			text-decoration: none;
		}

		/* Highlighted Data */
		.cellhighlight {
			font-weight: bold;
			color: #000000;
			text-decoration: none;
		}

		/* Results List (Table)*/
		.list_container  {
			/*background-color:#ffffff; */
			border:1px solid #cccccc;
			width: 98%;
		}

		/* Results List (Even Rows) */
		.list {
			color: #333333;
			background-color:#fff;
		}

		/* Results List (Odd Rows) */
		.listodd {
			color: #333333;
			background-color:#eee;
		}

		/* Results List (Highlighted Rows) */
		.listhighlight{
			color:#ffffff;
			background-color:#d92307;
			font-weight: bold;
		}

		/* Results Lists - Header Row */
		.list_heading, .list_heading A, .list_heading td {
			color: #fff;
			font-weight:bold;
			margin: 10px;
		    background-color: #c33b3b;
		}

		/* Results List Navigation - Active Page, forward & back buttons */
		.page_links_on, .page_links_on:hover {
			font-weight: bold;
		}

		/* Page Titles */
		.title {
			color:#000000;
			font-weight: bold;
		}

		/* Messages */
		.message {
			color:#c33b3b;
			font-weight: bold;
		}

		/* Label for required fields */
		.required_field {
			font-weight: bold;
		}

		/* Product Names (Typically are links to view detail)*/
		.product_link, .shipping_name {
			color: #c51616;
			font-weight: bold;
		}

		/* Product  Link mouseovers */
		.product_link:hover {
			color:#831212;
			font-weight: bold;
		}

		/* Category Names (Typically are links to view detail)*/
		.category_link, .category_list h1 {
			color: #c51616;
			font-weight: bold;
		}

		/* Category Link mouseovers */
		.category_link:hover {
			color:#831212;
			font-weight: bold;
		}

		/* Horrizontal Rule (line/seperator) */
		.hr { 
		    border-bottom: solid 1px;
		    color: #cccccc;
		    padding: 4px;
		}


		.category_list {
		   border-spacing: 15px;
		   border-collapse: expression('separate', cellSpacing = '15px'); /* border-spacing for IE */
		}

		.category_list .image img {
			float: left;
			border: 1px solid #cccccc;
			margin-right: 10px;
		}

		/* Catalog - List of departments, classes, finelines */
		.category_list_T {
			border-spacing: 3px;
		   	border-collapse: expression('separate', cellSpacing = '3px'); /* border-spacing for IE */
		}

		.category_list_T td {
			background: #fff;
			border: 1px solid #cccccc;
		}

		.category_list_T td:hover {
			background-color: #eee;
		}

		/* Customize of thumbnails in product listings*/
		.producttable .product_thumbnail {
			text-align: center;
			border: 1px solid #cccccc;
			background-color: #ffffff;
			padding: 5px;
		}

		/* Product Listings */
		.producttable TD, .product_list, .product_list_detailed {
			padding: 5px;
		}

		/* Product links for product listing pages */
		.product_list .product_link, .shipping_name {
			float: left;
			width: 68%;
		}

		/* Descriptions for product listing pages */
		.product_list .product_description {
			width: 68%;
			float: right;
		}

		/* Purhcase buttons and pricing for product listing pages */
		.product_list .product_price, .product_list .product_form, .shipping_price {
			float: right;
			width: 13%;
			text-align: center;
			align: center;
		}

		/* Thumbnail images on product listing page */
		.product_list .product_thumbnail {
			float: left;
			clear: left;
			width: 13%;
			overflow: hidden;
		}

		/* Images on product listing page (detailed mode) */
		.product_list_detailed .product_image {
			float: left;
			width: 39%;
			overflow: hidden;
		}

		/* Product details on product listing page (detailed mode)*/
		.product_list_detailed .product_data {
			float: right;
			background: #ffffff;
			width: 59%;
		}

		/* Purhcase buttons and pricing for product listing pages (detailed mode) */
		.product_list_detailed .product_form, .product_list_detailed .product_price {
			float: right;
			width: 25%;
			text-align: center;
		}

		/* Purchase buttons on product detail page */
		.product_detail .product_form {
			float: right;
			width: 40%;
			text-align: center;
		}

		/* Product links on product listing pages (detailed mode) */
		.product_list_detailed .product_link {
			width: 75%;
			float: left;
		}

		/* Labels for inventory options/data when viewing product lists & detail */
		.product_list_detailed .label, .product_detail .label {
			width: 40%;
			float: left;
			clear: left;
		}

		/* List of stores/locations when displaying quantity available */
		#locationtable ul li {
	        list-style-type: none;
	        clear: left;
	        margin-left: 10px;
	        white-space:nowrap;
		}

		/* List of stores/locations when displaying quantity available - Store names */
		#locationtable label {
	        width: 40%;
	        clear: left;
	        float: left;
	        padding-right: 10px;
		}

		/* List of stores/locations when displaying quantity available - Active Store */
		#locationtable .active {
	        font-weight: bold;
	        color: #000000;
		}


		/* Seperator line / spacing between products in a list.*/
		.product_list_seperator, .product_multi {
			border-bottom: 1px solid #cccccc;
		}

		/* iNet 1 style product grid display, and description for multi-item style products. (in multi-item group mode) */
		.product_list_detailed .product_grid, .product_multi .description {
			float: left;
			text-align: left;
		}

		/* Purchase buttons for multi-item style products (in multi-item group mode) */
		.product_multi .form {
			float:right;
		}

		/* Catalog - search refining */
		#search_refine {
			display: block;
		}

		/* Catalog - search refining - Button to refine search results */
		#search_refine button {
			width:100%;
			border: 1px solid #cccccc;
			background-color: #eeeeee;
			font-weight: bold;
			padding: 0;
			margin: 0;
		}

		/* Catalog - search refining - box containing options */
		#search_refine div.refine_box {
			width:96%;
			padding: 4px;
		}

		/* Catalog - search refining - List of options */
		#search_refine ul {
			list-style-type:none;
			margin: 0;
			padding: 5px;
			display:block;
		}

		/* Catalog - search refining - List Items */
		#search_refine li {
			width: 48%;
			float: left;
		}


		/* Recently viewed items - main box */
		#recently_viewed .recently_viewed{
			text-align: left;
			border-top: 1px dotted #cccccc;
			width: 98%;
			margin-top: 10px;
			padding: 5px;
		}

		/* Recently viewed items - Product */
		#recently_viewed .recent_product {
			float: left;
			text-align: center;
			width: 24%;
			padding: 0px;
			margin: auto;
			height: 100%;
			padding-top: 10px;
		}

		/* Recently viewed items - Price */
		#recently_viewed .product_price {
			
		}

		/* Revently viewed items - title */
		#recently_viewed h1 {
			color: #333333;
			font-weight: bold;
			display:inline;
			clear:right;
		}

		#checkout_position {
			width: 98%;
			margin: 5px 0 0 0;
		}

		#checkout_position .checkout_step {
			float: left;
			width: 24%;
			padding: 2px;
			margin: 0px;
			border-top: 2px solid #cccccc;
		}

		#checkout_position .complete_step {
			border-top: 2px solid #000000;
		}

		#checkout_position .active_step {
			border-top: 2px solid #d92307;
			color: #d92307;
			font-weight: bold;
		}

		.up_sell_products .related_table td, .cross_sell_products .related_table td {
			padding: 6px;	
		}

		/***************************/
		/*    PRINTER DIRECTIONS   */
		/***************************/
		@media print {
		 	 #header, #menuboxes { display: none; }
		}

		/***************************/
		/*    POPUP DIRECTIONS     */
		/***************************/

		.popup_body, .email_body {
		  padding: 10px;
		  background: #ffffff;
		  color: #333333;
		}


		/***********************/
		/*    HELPER CLASSES   */
		/***********************/

		.clear:after {
		  height: 0px;
		  clear: both;
		  display: block;
		  visibility: hidden;
		  content: ".";
		}
		.clear {
		  display: inline-block;
		}
		.clear-simple {
		  clear: both;
		}

		.hide {
		  display: none;
		}




		input[name="store_po"]{
			width: auto;
		}


	}
}