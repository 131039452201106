@import "foundation_core";

$color-p1:#B5202C;
$color-g1:#2E2E2E;
$color-g2:#262626;
$color-s1:#F7F7F7;

body#body{
	position: relative;
	min-width: 320px;
	font-family: 'Montserrat',Arial,sans-serif !important;
	background-color: $color-p1;
	h1,h2,h3,h4,h5,h6{
		font-family: 'Montserrat';
		font-weight: bold;
		text-transform: uppercase;
	}
	.font-montser{
		font-family: 'Montserrat';
	}
	.font-mosquito{
		font-family: 'Mosquito Fiesta';
	}
	.font-brusher{
		font-family: 'Brusher';
	}
	a{
		color: $color-p1;
	}
	h1,h1 a,
	h2,h2 a,
	h3,h3 a,
	h4,h4 a,
	h5,h5 a,
	h6,h6 a, {
		color: $color-g2;
	}
	.pad-all{
		padding: 4em 0.625rem;
		@include breakpoint(medium){
			padding: 4rem;
		}
	}
	.pad-tb{
		padding-top: 4em;
		padding-bottom: 4em;
	}
	.inline-block{
		display: inline-block;
	}
	.background-cover{
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	.left{
		float: left;
		clear: none;
	}
	.right{
		float: right;
		clear: none;
	}
	span{
		&.column,
		&.columns{
			display: inline-block;
		}
		&.row{
			display: block;
		}
	}
	.aspect-ratio-1x1{
		width: 100%;
		position: relative;
		display: inline-block;
		vertical-align: top;
		z-index: 1;
		&:before{
			display: block;
			content: "";
			width: 100%;
			padding-top: 100%;
		}
		> .ar-content{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}
	.aspect-ratio-16x9{
		width: 100%;
		position: relative;
		display: inline-block;
		vertical-align: top;
		z-index: 1;
		&:before{
			display: block;
			content: "";
			width: 100%;
			padding-top: 56.25%;
		}
		> .ar-content{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			iframe{
				width: 100%;
				height: 100%;
			}
		}
	}
	.button{
		color: $white;
		border-radius: 3px;
	}
	#bgvid{
	  position: fixed;
	  top: 0;
	  left: 0;
	  right: 0;
	  margin: 0 auto;
	  min-width: 100vw;
	  min-height: 100vh;
	  z-index: -100;
	}
}

#main-nav{
	min-width: 320px;
	top: 0;
	position: fixed;
	background-color: $color-p1;
	width: 100%;
	height: 96px;
	padding: 4px 0;
	z-index: 9998;
	border-bottom: 1px solid $medium-gray;
	@include breakpoint(large){
		top: 33px;
	}
	ul{
		list-style: none;
		margin:0;
		padding: 0; 
	}
	.left.company-logo{
		a{
			display: inline-block;
			color: $color-s1;
			i{
				display: inline-block;
				font-size: 88px;
				height: 88px;
				transition: font-size 0.25s ease-in-out;
			}
		}
	}
	#head-band{
		color: $white;
		line-height: 2rem;
		a{
			color: $white;
			&:hover{
				color: $dark-gray;
			}
		}
		.row{
			max-width: 75rem;
			margin: 0 auto;
		}
		.left,.right{
			float: none;
			display: block;
		}
		form#search{
			max-width: 320px;
			width: 100%;
			vertical-align: top;
			margin: 1rem auto;
			input{
				display: inline-block;
				margin: 0;
				padding: 0 10px;
				height: 3rem;
				line-height: 3rem;
				vertical-align: baseline;
				border: none;
				box-sizing: border-box;
				font-size: 1.5rem;
				&.search{
					transition: none;
					background-color: #efefef;
					-moz-box-shadow:    inset 0 0 1px #000000;
				    -webkit-box-shadow: inset 0 0 1px #000000;
				    box-shadow:         inset 0 0 1px #000000;
					&:focus{
						background-color: $white;
						border: none !important;
						-moz-box-shadow:    inset 0 0 3px #333333;
				        -webkit-box-shadow: inset 0 0 3px #333333;
				    	box-shadow:         inset 0 0 3px #333333;
					}
				}
				&.button{
					width: 100%;
					text-align: center;
					background-color: #9a1b25;
				}
			}
			@include breakpoint(medium){

			}
			@include breakpoint(large){
				display: inline-block;
				width: 320px;
				margin: 0;
				input{
					height: 1.5rem;
					line-height: 1.5rem;
					font-size: 1rem;
					&.search{
						width: 66.66%;
					}
					&.button{
						width: 33.33%;
					}
				}
			}
		}
		.social-icons{
			a{
				font-size: 1.5rem;
				display: inline-block;
				margin-left: 1rem;
			}
		}
		.company-hours{
			display: block;
			>span{
				display: block;
			}
		}
		@include breakpoint(large){
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			background-color: $color-p1;
			border-bottom: 1px solid $medium-gray;
			.left{
				float: left;
				display: inline-block;
			}
			.right{
				float: right;
				display: inline-block;
			}
			ul{
				li{
					display: inline-block;
				}
			}
			.social-icons{
				margin-left: 1rem;
				border-left: 1px solid $medium-gray;
				padding-left: 1rem;
				a{
					font-size: 1rem;
					margin-left: 0.5rem;
				}
			}
			.company-hours{
				display: inline-block;
				margin-left: 1rem;
				border-left: 1px solid $medium-gray;
				padding-left: 1rem;
				>span{
					display: inline-block;
					&:first-child:after{
						content:",";
					}
				}
			}
		}
	}
	.right.nav-button{
		#switch{
			position: absolute;
			top: 25px;
			right: 10px;
			cursor: pointer;
			display: inline-block;
			vertical-align: top;
			span{
				display: block;
				line-height: 4px;
				height: 4px;
				width: 40px;
				margin: 8px;
				background-color: $color-s1;
				&.middle{
					background-color: $color-s1;
					&:before{
						content: " ";
						display: block;
						background-color: $color-s1;
						width: 100%;
						height: 100%;
					}
				}
				
			}
		}
	}
	.nav{
		display: none;
	}
	.mobile-nav-open &{
		position: fixed;
		top: 0;
		height: 100%;
		>.row>.column{
			height: 100vh;
		}
		.right.nav-button{
			#switch{
				span{
					&.top,
					&.bottom{
						opacity: 0;
					}
					&.middle{
						transform: rotate(45deg);
						&:before{
							transform: rotate(90deg);
						}
					}
				}
			}
		}
		.nav{
			width: 100%;
			clear: both;
			display: block;
			max-height: 80%;
			overflow-y: scroll;
			overflow-x: hidden;
			border-top: 1px solid $white;
			nav{
				width: 100%;
				max-width: 320px;
				margin: 0 auto;
				.menu{
					margin-top: 0.5rem;
					border-top: 1px solid $white;
					li{
						display: block;
						margin: 0.25rem 0;
						a{
							width: 100%;
							text-align: center;
							font-size: 1.5rem;
							text-transform: uppercase;
							padding: 0.5rem 0;
							color: $white;
							&:hover,
							&:focus{
								background-color: $color-p1;
								color: #f15353;
							}
						}
						&.active > a{
							background-color: $color-p1;
							color: #f15353;
						}
					}
				}
			}
		}
	}
	@include breakpoint(large){
		transition: height 0.25s ease-in-out;
		.nav{
			float: right;
			display: inline-block;
			.menu{
				list-style: none;
				margin: 0;
				padding: 0;
				li{
					display: inline-block;
					margin-left: 16px;
					a{
						padding: 0;
						line-height: 88px;
						vertical-align: middle;
						color: $color-s1;
						font-family: 'Montserrat';
						font-weight: bold;
						font-size: 0.9rem;
						text-transform: uppercase;
						transition: line-height 0.25s ease-in-out;
						&:hover{
							color: #f15353;
						}
						@include breakpoint(xlarge){
							font-size: 1rem;
						}
					}
					&.active a{
						color: #f15353;
						background-color: transparent;
					}
					&:first-child{
						margin-left: 0;
					}
				}
			}
		}
		.nav-shrink &{
			height: 52px;
			.left.company-logo a i{
				font-size: 44px;
				height: 44px;
			}
			.nav .menu li a{
				line-height: 44px;
			}
		}
	}
}

.geg-page-content{
	background-color: #F7F7F7;
	margin-top: 96px;
	@include breakpoint(large){
		margin-top: 129px;
	}
}

footer#footer{
	background-color: $color-g1;
	color: $white;
	a{
		color: $white;
		&:hover{
			color: $dark-gray;
		}
	}
	#sidebar-footer{
		.widget{
			padding-top: 2rem;
			padding-bottom: 2rem;
			text-align: center;
			@include breakpoint(medium){
				text-align: left;
				padding-top: 6rem;
				padding-bottom: 6rem;
			}
			.widgettitle{
				color: $white;
				font-size: 1.2rem;
			}
			ul.menu{
				list-style: none;
				padding: 0;
				margin: 0;
				font-weight: bold;
			}
			.icon-logo{
				font-size: 12rem;
			}
			[class^="company-"], [class*=" company-"]{
				display: block;
			}
			.company-hours{
				span{
					display: block;
				}
			}
		}
	}
	#lower-footer{
		background-color: $color-g2;
		color: $dark-gray;
		font-size: 0.8rem;
		padding-top: 3rem;
		padding-bottom: 3rem;
		a{
			color: $dark-gray;
			&:hover{
				color: $white;
			}
		}
	}
}

/*************
 *** FONTS ***
 *************/

@font-face {
	font-family: 'Mosquito';
	src: url('../fonts/textual/MosquitoFiesta.eot');
	src: url('../fonts/textual/MosquitoFiesta.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/textual/MosquitoFiesta.woff') format('woff'),
		 url('../fonts/textual/MosquitoFiesta.ttf') format('truetype'),
		 url('../fonts/textual/MosquitoFiesta.svg#MosquitoFiesta') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Brusher';
	src: url('../fonts/textual/Brusher-Regular.eot');
	src: url('../fonts/textual/Brusher-Regular.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/textual/Brusher-Regular.woff') format('woff'),
		 url('../fonts/textual/Brusher-Regular.ttf') format('truetype'),
		 url('../fonts/textual/Brusher-Regular.svg#Brusher-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/textual/Montserrat-Regular.eot');
	src: url('../fonts/textual/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/textual/Montserrat-Regular.woff') format('woff'),
		 url('../fonts/textual/Montserrat-Regular.ttf') format('truetype'),
		 url('../fonts/textual/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/textual/Montserrat-Bold.eot');
	src: url('../fonts/textual/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/textual/Montserrat-Bold.woff') format('woff'),
		 url('../fonts/textual/Montserrat-Bold.ttf') format('truetype'),
		 url('../fonts/textual/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}

/*************
 ** ICOMOON **
 *************/

/* DO NOT copy and paste this section when you get new icons, leave this as is: */
@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon/fonts/icomoon.eot?8mw2f8');
    src: url('../fonts/icomoon/fonts/icomoon.eot?8mw2f8#iefix') format('embedded-opentype'),
         url('../fonts/icomoon/fonts/icomoon.ttf?8mw2f8') format('truetype'),
         url('../fonts/icomoon/fonts/icomoon.woff?8mw2f8') format('woff'),
         url('../fonts/icomoon/fonts/icomoon.svg?8mw2f8#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
/* END DO NOT copy */

/* copy paste here when you get new icons: */
.icon-logo:before {
    content: "\e900";
}
.icon-facebook:before {
    content: "\e901";
}
.icon-google-plus:before {
    content: "\e902";
}
.icon-houzz:before {
    content: "\e903";
}
.icon-instagram:before {
    content: "\e904";
}
.icon-pinterest:before {
    content: "\e905";
}
.icon-twitter:before {
    content: "\e906";
}
.icon-youtube:before {
    content: "\e907";
}
/* end copy paste here */